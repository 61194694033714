<template>
    <m-loading :loading="pageLoading" id="GuideGame">
        <div class="content">
            <div class="video" ref="video">
                <Video v-if="gameInfo.contentType === 4" :game-info="gameInfo"
                       @startPlay="startPlay" @endPlay="endPlay"/>
            </div>
            <h2 class="video-name">{{ this.gameInfo.contentName }}</h2>
            <ul>
                <li>
                    <h3>
                        <van-icon name="bookmark"/>
                        视频简介
                    </h3>
                    <p>下面演示了结合单元格来使用日历组件的用法，日期选择完成后会触发</p>
                </li>
                <li>
                    <h3>
                        <van-icon name="bookmark"/>
                        游戏简介
                    </h3>
                    <p>下面演示了结合单元格来使用日历组件的用法，日期选择完成后会触发</p>
                </li>
            </ul>
        </div>
        <van-button @click="startGame" :disabled="btn.disabled" :loading="btn.loading"
                    loading-text="开始游戏"
                    type="info" round block>开始游戏</van-button>
    </m-loading>
</template>

<script>
import Apis from "@/requests/api";
import Video from "./components/Video";

export default {
    name: "GuideGame",
    components: {Video},
    data() {
        return {
            gameInfo: {
                "contentSnapshotId": 343634637222149,
                "contentSnapshotGroupId": 343634637222150,
                "contentType": 4,
                "contentId": 337320685176069,
                "contentName": "超级秒表指导",
                "icon": "https://static.cdn.iquizoo.com/eval3.x/pro/content/evaluationgame/add/%E5%A4%A7%E8%84%91%E8%AE%AD%E7%BB%83%E8%90%A5-%E6%B8%B8%E6%88%8F%E6%8C%87%E5%AF%BC.png",
                "url": "cc951f359b1d4bc784361a5bc26f53f6",
                "coverPlanUri": "",
                "description": "",
                "contentVersion": "1.0.0",
                "isFinished": false,
                "stage": 1,
                "executionsNumber": 1,
                "inFactExecutionsNumber": 0,
                "displayOrder": 1,
                "firstPlay": false,
                "enabled": true,
                "abilitys": [{
                    "name": "反应力",
                    "icon": "https://static.cdn.iquizoo.com/brain/image/question/168232205017937.png",
                    "color": "#b61285"
                }]
            },
            pageLoading: true,
            btn: {
                loading: false,
                disabled: true
            },
            videoData: {}
        }
    },
    mounted() {
        document.title = this.gameInfo.contentName;
    },
    methods: {
        /**
         * 视频初始化完成
         * @param player
         */
        startPlay(player) {
            this.$refs.video.style.height = (player.tag.videoHeight / player.tag.videoWidth
                * this.$refs.video.offsetWidth) + "px";
            this.pageLoading = false;
            player.play();
        },
        /**
         * 视频播放结束
         * @param data
         */
        endPlay(data) {
            this.videoData = data;
            this.btn.disabled = false;
        },
        startGame() {
            const params = {
                contentSnapshotId: this.gameInfo.contentSnapshotId,
                ...this.videoData
            };
            this.btn.loading = true;
            this.$http.post(Apis.Game.updateCompletionTimes, params).then(res => {
                this.btn.loading = false;
                this.$router.push("/startGame")
            });
        }
    }
}
</script>

<style scoped lang="less">
#GuideGame {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        flex-grow: 1;
    }

    .van-button {
        flex-shrink: 0;
        width: 80%;
        max-width: 300px;
        margin: 0 auto 25px;
    }

    .video {
        width: 100%;
        height: 211px;
        //transition: height 0.3s linear;
    }

    .video-name {
        padding: 10px 20px;
        background: #000;
        color: #fff;
        font-size: 14px;
    }

    ul {
        margin-top: 20px;
        padding: 25px;

        li {
            margin-bottom: 30px;
        }

        h3 {
            font-size: 14px;
        }

        p {
            font-size: 13px;
            color: #666;
            margin-top: 10px;
        }
    }
}
</style>